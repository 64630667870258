const getStops = (line) => {
  if (line == null) {
    return "";
  }
  const stops = [
    {
      line: "1",
      stops: [
        {
          borough: "Bronx",
          service: "1",
          stop_id: "101",
          stop_name: "Van Cortlandt Park-242 St",
        },
        {
          borough: "Bronx",
          service: "1",
          stop_id: "103",
          stop_name: "238 St",
        },
        {
          borough: "Bronx",
          service: "1",
          stop_id: "104",
          stop_name: "231 St",
        },
        {
          borough: "Bronx",
          service: "1",
          stop_id: "106",
          stop_name: "Marble Hill-225 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "107",
          stop_name: "215 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "108",
          stop_name: "207 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "109",
          stop_name: "Dyckman St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "110",
          stop_name: "191 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "111",
          stop_name: "181 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "112",
          stop_name: "168 St-Washington Hts",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "113",
          stop_name: "157 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "114",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "115",
          stop_name: "137 St-City College",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "116",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "117",
          stop_name: "116 St-Columbia University",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "118",
          stop_name: "Cathedral Pkwy (110 St)",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "119",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "120",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "121",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "122",
          stop_name: "79 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "123",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "124",
          stop_name: "66 St-Lincoln Center",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "125",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "126",
          stop_name: "50 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "127",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "128",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "129",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "130",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "131",
          stop_name: "18 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "132",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "133",
          stop_name: "Christopher St-Sheridan Sq",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "134",
          stop_name: "Houston St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "135",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "136",
          stop_name: "Franklin St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "137",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "138",
          stop_name: "WTC Cortlandt",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "139",
          stop_name: "Rector St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "140",
          stop_name: "South Ferry Loop",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "142",
          stop_name: "South Ferry",
        },
      ],
    },
    {
      line: "2",
      stops: [
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "120",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "121",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "122",
          stop_name: "79 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "123",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "124",
          stop_name: "66 St-Lincoln Center",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "125",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "126",
          stop_name: "50 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "127",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "128",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "129",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "130",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "131",
          stop_name: "18 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "132",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "133",
          stop_name: "Christopher St-Sheridan Sq",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "134",
          stop_name: "Houston St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "135",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "136",
          stop_name: "Franklin St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "137",
          stop_name: "Chambers St",
        },
        {
          borough: "Bronx",
          service: "2",
          stop_id: "201",
          stop_name: "Wakefield-241 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "204",
          stop_name: "Nereid Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "205",
          stop_name: "233 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "206",
          stop_name: "225 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "207",
          stop_name: "219 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "208",
          stop_name: "Gun Hill Rd",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "209",
          stop_name: "Burke Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "210",
          stop_name: "Allerton Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "211",
          stop_name: "Pelham Pkwy",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "212",
          stop_name: "Bronx Park East",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "213",
          stop_name: "E 180 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "214",
          stop_name: "West Farms Sq-E Tremont Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "215",
          stop_name: "174 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "216",
          stop_name: "Freeman St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "217",
          stop_name: "Simpson St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "218",
          stop_name: "Intervale Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "219",
          stop_name: "Prospect Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "220",
          stop_name: "Jackson Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "221",
          stop_name: "3 Av-149 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "222",
          stop_name: "149 St-Grand Concourse",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "224",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "225",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "226",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "227",
          stop_name: "Central Park North (110 St)",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "228",
          stop_name: "Park Place",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "229",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "230",
          stop_name: "Wall St",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "231",
          stop_name: "Clark St",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "232",
          stop_name: "Borough Hall",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "233",
          stop_name: "Hoyt St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "234",
          stop_name: "Nevins St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "235",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "236",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "237",
          stop_name: "Grand Army Plaza",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "238",
          stop_name: "Eastern Pkwy-Brooklyn Museum",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "239",
          stop_name: "Franklin Av-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "241",
          stop_name: "President St-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "242",
          stop_name: "Sterling St",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "243",
          stop_name: "Winthrop St",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "244",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "245",
          stop_name: "Beverly Rd",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "246",
          stop_name: "Newkirk Av-Little Haiti",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "247",
          stop_name: "Flatbush Av-Brooklyn College",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "248",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "249",
          stop_name: "Kingston Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "250",
          stop_name: "Crown Hts-Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "251",
          stop_name: "Sutter Av-Rutland Rd",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "252",
          stop_name: "Saratoga Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "253",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "254",
          stop_name: "Junius St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "255",
          stop_name: "Pennsylvania Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "256",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "257",
          stop_name: "New Lots Av",
        },
      ],
    },
    {
      line: "3",
      stops: [
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "120",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "123",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "127",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "128",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "132",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "137",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "224",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "225",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "226",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "227",
          stop_name: "Central Park North (110 St)",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "228",
          stop_name: "Park Place",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "229",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "230",
          stop_name: "Wall St",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "231",
          stop_name: "Clark St",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "232",
          stop_name: "Borough Hall",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "233",
          stop_name: "Hoyt St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "234",
          stop_name: "Nevins St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "235",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "236",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "237",
          stop_name: "Grand Army Plaza",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "238",
          stop_name: "Eastern Pkwy-Brooklyn Museum",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "239",
          stop_name: "Franklin Av-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "248",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "249",
          stop_name: "Kingston Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "250",
          stop_name: "Crown Hts-Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "251",
          stop_name: "Sutter Av-Rutland Rd",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "252",
          stop_name: "Saratoga Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "253",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "254",
          stop_name: "Junius St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "255",
          stop_name: "Pennsylvania Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "256",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "257",
          stop_name: "New Lots Av",
        },
        {
          borough: "Manhattan",
          service: "3",
          stop_id: "301",
          stop_name: "Harlem-148 St",
        },
        {
          borough: "Manhattan",
          service: "3",
          stop_id: "302",
          stop_name: "145 St",
        },
      ],
    },
    {
      line: "4",
      stops: [
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "234",
          stop_name: "Nevins St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "235",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "236",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "237",
          stop_name: "Grand Army Plaza",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "238",
          stop_name: "Eastern Pkwy-Brooklyn Museum",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "239",
          stop_name: "Franklin Av-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "248",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "249",
          stop_name: "Kingston Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "250",
          stop_name: "Crown Hts-Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "251",
          stop_name: "Sutter Av-Rutland Rd",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "252",
          stop_name: "Saratoga Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "253",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "254",
          stop_name: "Junius St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "255",
          stop_name: "Pennsylvania Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "256",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "257",
          stop_name: "New Lots Av",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "401",
          stop_name: "Woodlawn",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "402",
          stop_name: "Mosholu Pkwy",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "405",
          stop_name: "Bedford Park Blvd-Lehman College",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "406",
          stop_name: "Kingsbridge Rd",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "407",
          stop_name: "Fordham Rd",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "408",
          stop_name: "183 St",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "409",
          stop_name: "Burnside Av",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "410",
          stop_name: "176 St",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "411",
          stop_name: "Mt Eden Av",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "412",
          stop_name: "170 St",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "413",
          stop_name: "167 St",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "414",
          stop_name: "161 St-Yankee Stadium",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "415",
          stop_name: "149 St-Grand Concourse",
        },
        {
          borough: "Bronx",
          service: "45",
          stop_id: "416",
          stop_name: "138 St-Grand Concourse",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "418",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "419",
          stop_name: "Wall St",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "420",
          stop_name: "Bowling Green",
        },
        {
          borough: "Brooklyn",
          service: "45",
          stop_id: "423",
          stop_name: "Borough Hall",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "621",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "622",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "623",
          stop_name: "110 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "624",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "625",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "626",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "627",
          stop_name: "77 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "628",
          stop_name: "68 St-Hunter College",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "629",
          stop_name: "59 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "630",
          stop_name: "51 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "631",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "632",
          stop_name: "33 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "633",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "634",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "635",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "636",
          stop_name: "Astor Pl",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "637",
          stop_name: "Bleecker St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "638",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "639",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "640",
          stop_name: "Brooklyn Bridge-City Hall",
        },
      ],
    },
    {
      line: "5",
      stops: [
        {
          borough: "Bronx",
          service: "25",
          stop_id: "204",
          stop_name: "Nereid Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "205",
          stop_name: "233 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "206",
          stop_name: "225 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "207",
          stop_name: "219 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "208",
          stop_name: "Gun Hill Rd",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "209",
          stop_name: "Burke Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "210",
          stop_name: "Allerton Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "211",
          stop_name: "Pelham Pkwy",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "212",
          stop_name: "Bronx Park East",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "213",
          stop_name: "E 180 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "214",
          stop_name: "West Farms Sq-E Tremont Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "215",
          stop_name: "174 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "216",
          stop_name: "Freeman St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "217",
          stop_name: "Simpson St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "218",
          stop_name: "Intervale Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "219",
          stop_name: "Prospect Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "220",
          stop_name: "Jackson Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "221",
          stop_name: "3 Av-149 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "222",
          stop_name: "149 St-Grand Concourse",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "234",
          stop_name: "Nevins St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "235",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "239",
          stop_name: "Franklin Av-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "241",
          stop_name: "President St-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "242",
          stop_name: "Sterling St",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "243",
          stop_name: "Winthrop St",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "244",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "245",
          stop_name: "Beverly Rd",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "246",
          stop_name: "Newkirk Av-Little Haiti",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "247",
          stop_name: "Flatbush Av-Brooklyn College",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "248",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "249",
          stop_name: "Kingston Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "250",
          stop_name: "Crown Hts-Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "251",
          stop_name: "Sutter Av-Rutland Rd",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "252",
          stop_name: "Saratoga Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "253",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "254",
          stop_name: "Junius St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "255",
          stop_name: "Pennsylvania Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "256",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "257",
          stop_name: "New Lots Av",
        },
        {
          borough: "Bronx",
          service: "45",
          stop_id: "416",
          stop_name: "138 St-Grand Concourse",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "418",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "419",
          stop_name: "Wall St",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "420",
          stop_name: "Bowling Green",
        },
        {
          borough: "Brooklyn",
          service: "45",
          stop_id: "423",
          stop_name: "Borough Hall",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "501",
          stop_name: "Eastchester-Dyre Av",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "502",
          stop_name: "Baychester Av",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "503",
          stop_name: "Gun Hill Rd",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "504",
          stop_name: "Pelham Pkwy",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "505",
          stop_name: "Morris Park",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "621",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "626",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "629",
          stop_name: "59 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "631",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "635",
          stop_name: "14 St-Union Sq",
        },
      ],
    },
    {
      line: "6",
      stops: [
        {
          borough: "Bronx",
          service: "6",
          stop_id: "601",
          stop_name: "Pelham Bay Park",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "602",
          stop_name: "Buhre Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "603",
          stop_name: "Middletown Rd",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "604",
          stop_name: "Westchester Sq-E Tremont Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "606",
          stop_name: "Zerega Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "607",
          stop_name: "Castle Hill Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "608",
          stop_name: "Parkchester",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "609",
          stop_name: "St Lawrence Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "610",
          stop_name: "Morrison Av-Soundview",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "611",
          stop_name: "Elder Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "612",
          stop_name: "Whitlock Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "613",
          stop_name: "Hunts Point Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "614",
          stop_name: "Longwood Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "615",
          stop_name: "E 149 St",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "616",
          stop_name: "E 143 St-St Mary's St",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "617",
          stop_name: "Cypress Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "618",
          stop_name: "Brook Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "619",
          stop_name: "3 Av-138 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "621",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "622",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "623",
          stop_name: "110 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "624",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "625",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "626",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "627",
          stop_name: "77 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "628",
          stop_name: "68 St-Hunter College",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "629",
          stop_name: "59 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "630",
          stop_name: "51 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "631",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "632",
          stop_name: "33 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "633",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "634",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "635",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "636",
          stop_name: "Astor Pl",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "637",
          stop_name: "Bleecker St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "638",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "639",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "640",
          stop_name: "Brooklyn Bridge-City Hall",
        },
      ],
    },
    {
      line: "A",
      stops: [
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A02",
          stop_name: "Inwood-207 St",
        },
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A03",
          stop_name: "Dyckman St",
        },
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A05",
          stop_name: "190 St",
        },
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A06",
          stop_name: "181 St",
        },
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A07",
          stop_name: "175 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A09",
          stop_name: "168 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A10",
          stop_name: "163 St-Amsterdam Av",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A11",
          stop_name: "155 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A12",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A14",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A15",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A16",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A17",
          stop_name: "Cathedral Pkwy (110 St)",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A18",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A19",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A20",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A21",
          stop_name: "81 St-Museum of Natural History",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A22",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A24",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A25",
          stop_name: "50 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A27",
          stop_name: "42 St-Port Authority Bus Terminal",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A28",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A30",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A31",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A32",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A33",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A34",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A36",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A38",
          stop_name: "Fulton St",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A40",
          stop_name: "High St",
        },
        {
          borough: "Brooklyn",
          service: "ACF",
          stop_id: "A41",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "ACG",
          stop_id: "A42",
          stop_name: "Hoyt-Schermerhorn Sts",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A43",
          stop_name: "Lafayette Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A44",
          stop_name: "Clinton-Washington Avs",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A45",
          stop_name: "Franklin Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A46",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A47",
          stop_name: "Kingston-Throop Avs",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A48",
          stop_name: "Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A49",
          stop_name: "Ralph Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A50",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A51",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A52",
          stop_name: "Liberty Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A53",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A54",
          stop_name: "Shepherd Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A55",
          stop_name: "Euclid Av",
        },
        {
          borough: "Brooklyn",
          service: "A",
          stop_id: "A57",
          stop_name: "Grant Av",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A59",
          stop_name: "80 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A60",
          stop_name: "88 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A61",
          stop_name: "Rockaway Blvd",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A63",
          stop_name: "104 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A64",
          stop_name: "111 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A65",
          stop_name: "Ozone Park-Lefferts Blvd",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H01",
          stop_name: "Aqueduct Racetrack",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H02",
          stop_name: "Aqueduct-N Conduit Av",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H03",
          stop_name: "Howard Beach-JFK Airport",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H04",
          stop_name: "Broad Channel",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H06",
          stop_name: "Beach 67 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H07",
          stop_name: "Beach 60 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H08",
          stop_name: "Beach 44 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H09",
          stop_name: "Beach 36 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H10",
          stop_name: "Beach 25 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H11",
          stop_name: "Far Rockaway-Mott Av",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H12",
          stop_name: "Beach 90 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H13",
          stop_name: "Beach 98 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H14",
          stop_name: "Beach 105 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H15",
          stop_name: "Rockaway Park-Beach 116 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H19",
          stop_name: "Broad Channel",
        },
      ],
    },
    {
      line: "C",
      stops: [
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A09",
          stop_name: "168 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A10",
          stop_name: "163 St-Amsterdam Av",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A11",
          stop_name: "155 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A12",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A14",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A15",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A16",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A17",
          stop_name: "Cathedral Pkwy (110 St)",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A18",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A19",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A20",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A21",
          stop_name: "81 St-Museum of Natural History",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A22",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A24",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A25",
          stop_name: "50 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A27",
          stop_name: "42 St-Port Authority Bus Terminal",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A28",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A30",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A31",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A32",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A33",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A34",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A36",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A38",
          stop_name: "Fulton St",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A40",
          stop_name: "High St",
        },
        {
          borough: "Brooklyn",
          service: "ACF",
          stop_id: "A41",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "ACG",
          stop_id: "A42",
          stop_name: "Hoyt-Schermerhorn Sts",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A43",
          stop_name: "Lafayette Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A44",
          stop_name: "Clinton-Washington Avs",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A45",
          stop_name: "Franklin Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A46",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A47",
          stop_name: "Kingston-Throop Avs",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A48",
          stop_name: "Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A49",
          stop_name: "Ralph Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A50",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A51",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A52",
          stop_name: "Liberty Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A53",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A54",
          stop_name: "Shepherd Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A55",
          stop_name: "Euclid Av",
        },
      ],
    },
    {
      line: "E",
      stops: [
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A27",
          stop_name: "42 St-Port Authority Bus Terminal",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A28",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A30",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A31",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A32",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A33",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A34",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "BDE",
          stop_id: "D14",
          stop_name: "7 Av (M)",
        },
        {
          borough: "Manhattan",
          service: "E",
          stop_id: "E01",
          stop_name: "World Trade Center",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F01",
          stop_name: "Jamaica-179 St",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F02",
          stop_name: "169 St",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F03",
          stop_name: "Parsons Blvd",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F04",
          stop_name: "Sutphin Blvd",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F05",
          stop_name: "Briarwood",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F06",
          stop_name: "Kew Gardens-Union Tpke",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F07",
          stop_name: "75 Av",
        },
        {
          borough: "Queens",
          service: "EM",
          stop_id: "F09",
          stop_name: "Court Sq-23 St",
        },
        {
          borough: "Manhattan",
          service: "EM",
          stop_id: "F11",
          stop_name: "Lexington Av/53 St",
        },
        {
          borough: "Manhattan",
          service: "EM",
          stop_id: "F12",
          stop_name: "5 Av/53 St",
        },
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G05",
          stop_name: "Jamaica Center-Parsons/Archer",
        },
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G06",
          stop_name: "Sutphin Blvd-Archer Av-JFK Airport",
        },
        {
          borough: "Queens",
          service: "E",
          stop_id: "G07",
          stop_name: "Jamaica-Van Wyck",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G08",
          stop_name: "Forest Hills-71 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G09",
          stop_name: "67 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G10",
          stop_name: "63 Dr-Rego Park",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G11",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G12",
          stop_name: "Grand Av-Newtown",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G13",
          stop_name: "Elmhurst Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G14",
          stop_name: "Jackson Hts-Roosevelt Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G15",
          stop_name: "65 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G16",
          stop_name: "Northern Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G18",
          stop_name: "46 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G19",
          stop_name: "Steinway St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G20",
          stop_name: "36 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G21",
          stop_name: "Queens Plaza",
        },
      ],
    },
    {
      line: "G",
      stops: [
        {
          borough: "Brooklyn",
          service: "ACG",
          stop_id: "A42",
          stop_name: "Hoyt-Schermerhorn Sts",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F20",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F21",
          stop_name: "Carroll St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F22",
          stop_name: "Smith-9 Sts",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F23",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F24",
          stop_name: "7 Av",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F25",
          stop_name: "15 St-Prospect Park",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F26",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F27",
          stop_name: "Church Av",
        },
        {
          borough: "Queens",
          service: "G",
          stop_id: "G22",
          stop_name: "Court Sq",
        },
        {
          borough: "Queens",
          service: "G",
          stop_id: "G24",
          stop_name: "21 St",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G26",
          stop_name: "Greenpoint Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G28",
          stop_name: "Nassau Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G29",
          stop_name: "Metropolitan Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G30",
          stop_name: "Broadway",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G31",
          stop_name: "Flushing Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G32",
          stop_name: "Myrtle-Willoughby Avs",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G33",
          stop_name: "Bedford-Nostrand Avs",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G34",
          stop_name: "Classon Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G35",
          stop_name: "Clinton-Washington Avs",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G36",
          stop_name: "Fulton St",
        },
      ],
    },
    {
      line: "7",
      stops: [
        {
          borough: "Queens",
          service: "7",
          stop_id: "701",
          stop_name: "Flushing-Main St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "702",
          stop_name: "Mets-Willets Point",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "705",
          stop_name: "111 St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "706",
          stop_name: "103 St-Corona Plaza",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "707",
          stop_name: "Junction Blvd",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "708",
          stop_name: "90 St-Elmhurst Av",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "709",
          stop_name: "82 St-Jackson Hts",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "710",
          stop_name: "74 St-Broadway",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "711",
          stop_name: "69 St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "712",
          stop_name: "61 St-Woodside",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "713",
          stop_name: "52 St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "714",
          stop_name: "46 St-Bliss St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "715",
          stop_name: "40 St-Lowery St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "716",
          stop_name: "33 St-Rawson St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "718",
          stop_name: "Queensboro Plaza",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "719",
          stop_name: "Court Sq",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "720",
          stop_name: "Hunters Point Av",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "721",
          stop_name: "Vernon Blvd-Jackson Av",
        },
        {
          borough: "Manhattan",
          service: "7",
          stop_id: "723",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "7",
          stop_id: "724",
          stop_name: "5 Av",
        },
        {
          borough: "Manhattan",
          service: "7",
          stop_id: "725",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "7",
          stop_id: "726",
          stop_name: "34 St-Hudson Yards",
        },
      ],
    },
    {
      line: "B",
      stops: [
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A14",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A15",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A16",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A17",
          stop_name: "Cathedral Pkwy (110 St)",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A18",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A19",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A20",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A21",
          stop_name: "81 St-Museum of Natural History",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A22",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A24",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D03",
          stop_name: "Bedford Park Blvd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D04",
          stop_name: "Kingsbridge Rd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D05",
          stop_name: "Fordham Rd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D06",
          stop_name: "182-183 Sts",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D07",
          stop_name: "Tremont Av",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D08",
          stop_name: "174-175 Sts",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D09",
          stop_name: "170 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D10",
          stop_name: "167 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D11",
          stop_name: "161 St-Yankee Stadium",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D12",
          stop_name: "155 St",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D13",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "BDE",
          stop_id: "D14",
          stop_name: "7 Av (M)",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D15",
          stop_name: "47-50 Sts-Rockefeller Ctr",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D16",
          stop_name: "42 St-Bryant Pk",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D20",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D21",
          stop_name: "Broadway-Lafayette St",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D22",
          stop_name: "Grand St",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D24",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D25",
          stop_name: "7 Av (B)",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D26",
          stop_name: "Prospect Park",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D28",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D31",
          stop_name: "Newkirk Plaza",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D35",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D39",
          stop_name: "Sheepshead Bay",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D40",
          stop_name: "Brighton Beach",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
      ],
    },
    {
      line: "D",
      stops: [
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A15",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A24",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B12",
          stop_name: "9 Av",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B13",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B14",
          stop_name: "50 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B15",
          stop_name: "55 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B16",
          stop_name: "62 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B17",
          stop_name: "71 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B18",
          stop_name: "79 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B19",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B20",
          stop_name: "20 Av",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B21",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B22",
          stop_name: "25 Av",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B23",
          stop_name: "Bay 50 St",
        },
        {
          borough: "Bronx",
          service: "D",
          stop_id: "D01",
          stop_name: "Norwood-205 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D03",
          stop_name: "Bedford Park Blvd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D04",
          stop_name: "Kingsbridge Rd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D05",
          stop_name: "Fordham Rd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D06",
          stop_name: "182-183 Sts",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D07",
          stop_name: "Tremont Av",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D08",
          stop_name: "174-175 Sts",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D09",
          stop_name: "170 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D10",
          stop_name: "167 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D11",
          stop_name: "161 St-Yankee Stadium",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D12",
          stop_name: "155 St",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D13",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "BDE",
          stop_id: "D14",
          stop_name: "7 Av (M)",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D15",
          stop_name: "47-50 Sts-Rockefeller Ctr",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D16",
          stop_name: "42 St-Bryant Pk",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D20",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D21",
          stop_name: "Broadway-Lafayette St",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D22",
          stop_name: "Grand St",
        },
        {
          borough: "Brooklyn",
          service: "DFNQ",
          stop_id: "D43",
          stop_name: "Coney Island-Stillwell Av",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R31",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R32",
          stop_name: "Union St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R33",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R34",
          stop_name: "Prospect Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R35",
          stop_name: "25 St",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
      ],
    },
    {
      line: "F",
      stops: [
        {
          borough: "Brooklyn",
          service: "ACF",
          stop_id: "A41",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Queens",
          service: "F",
          stop_id: "B04",
          stop_name: "21 St-Queensbridge",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "B06",
          stop_name: "Roosevelt Island",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "B08",
          stop_name: "Lexington Av/63 St",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "B10",
          stop_name: "57 St",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D15",
          stop_name: "47-50 Sts-Rockefeller Ctr",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D16",
          stop_name: "42 St-Bryant Pk",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "FM",
          stop_id: "D18",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "FM",
          stop_id: "D19",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D20",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D21",
          stop_name: "Broadway-Lafayette St",
        },
        {
          borough: "Brooklyn",
          service: "FQ",
          stop_id: "D42",
          stop_name: "W 8 St-NY Aquarium",
        },
        {
          borough: "Brooklyn",
          service: "DFNQ",
          stop_id: "D43",
          stop_name: "Coney Island-Stillwell Av",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F01",
          stop_name: "Jamaica-179 St",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F02",
          stop_name: "169 St",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F03",
          stop_name: "Parsons Blvd",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F04",
          stop_name: "Sutphin Blvd",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F05",
          stop_name: "Briarwood",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F06",
          stop_name: "Kew Gardens-Union Tpke",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F07",
          stop_name: "75 Av",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "F14",
          stop_name: "2 Av",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "F15",
          stop_name: "Delancey St-Essex St",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "F16",
          stop_name: "East Broadway",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F18",
          stop_name: "York St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F20",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F21",
          stop_name: "Carroll St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F22",
          stop_name: "Smith-9 Sts",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F23",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F24",
          stop_name: "7 Av",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F25",
          stop_name: "15 St-Prospect Park",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F26",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F27",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F29",
          stop_name: "Ditmas Av",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F30",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F31",
          stop_name: "Avenue I",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F32",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F33",
          stop_name: "Avenue N",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F34",
          stop_name: "Avenue P",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F35",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F36",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F38",
          stop_name: "Avenue X",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F39",
          stop_name: "Neptune Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G08",
          stop_name: "Forest Hills-71 Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G14",
          stop_name: "Jackson Hts-Roosevelt Av",
        },
      ],
    },
    {
      line: "M",
      stops: [
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D15",
          stop_name: "47-50 Sts-Rockefeller Ctr",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D16",
          stop_name: "42 St-Bryant Pk",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "FM",
          stop_id: "D18",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "FM",
          stop_id: "D19",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D20",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D21",
          stop_name: "Broadway-Lafayette St",
        },
        {
          borough: "Queens",
          service: "EM",
          stop_id: "F09",
          stop_name: "Court Sq-23 St",
        },
        {
          borough: "Manhattan",
          service: "EM",
          stop_id: "F11",
          stop_name: "Lexington Av/53 St",
        },
        {
          borough: "Manhattan",
          service: "EM",
          stop_id: "F12",
          stop_name: "5 Av/53 St",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G08",
          stop_name: "Forest Hills-71 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G09",
          stop_name: "67 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G10",
          stop_name: "63 Dr-Rego Park",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G11",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G12",
          stop_name: "Grand Av-Newtown",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G13",
          stop_name: "Elmhurst Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G14",
          stop_name: "Jackson Hts-Roosevelt Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G15",
          stop_name: "65 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G16",
          stop_name: "Northern Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G18",
          stop_name: "46 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G19",
          stop_name: "Steinway St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G20",
          stop_name: "36 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G21",
          stop_name: "Queens Plaza",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M01",
          stop_name: "Middle Village-Metropolitan Av",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M04",
          stop_name: "Fresh Pond Rd",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M05",
          stop_name: "Forest Av",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M06",
          stop_name: "Seneca Av",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M08",
          stop_name: "Myrtle-Wyckoff Avs",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M09",
          stop_name: "Knickerbocker Av",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M10",
          stop_name: "Central Av",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M11",
          stop_name: "Myrtle Av",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M12",
          stop_name: "Flushing Av",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M13",
          stop_name: "Lorimer St",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M14",
          stop_name: "Hewes St",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M16",
          stop_name: "Marcy Av",
        },
        {
          borough: "Manhattan",
          service: "JMZ",
          stop_id: "M18",
          stop_name: "Delancey St-Essex St",
        },
      ],
    },
    {
      line: "J",
      stops: [
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G05",
          stop_name: "Jamaica Center-Parsons/Archer",
        },
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G06",
          stop_name: "Sutphin Blvd-Archer Av-JFK Airport",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J12",
          stop_name: "121 St",
        },
        {
          borough: "Queens",
          service: "J",
          stop_id: "J13",
          stop_name: "111 St",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J14",
          stop_name: "104 St",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J15",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "J",
          stop_id: "J16",
          stop_name: "85 St-Forest Pkwy",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J17",
          stop_name: "75 St-Elderts Ln",
        },
        {
          borough: "Brooklyn",
          service: "J",
          stop_id: "J19",
          stop_name: "Cypress Hills",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J20",
          stop_name: "Crescent St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J21",
          stop_name: "Norwood Av",
        },
        {
          borough: "Brooklyn",
          service: "J",
          stop_id: "J22",
          stop_name: "Cleveland St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J23",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J24",
          stop_name: "Alabama Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J27",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J28",
          stop_name: "Chauncey St",
        },
        {
          borough: "Brooklyn",
          service: "J",
          stop_id: "J29",
          stop_name: "Halsey St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J30",
          stop_name: "Gates Av",
        },
        {
          borough: "Brooklyn",
          service: "J",
          stop_id: "J31",
          stop_name: "Kosciuszko St",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M11",
          stop_name: "Myrtle Av",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M12",
          stop_name: "Flushing Av",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M13",
          stop_name: "Lorimer St",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M14",
          stop_name: "Hewes St",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M16",
          stop_name: "Marcy Av",
        },
        {
          borough: "Manhattan",
          service: "JMZ",
          stop_id: "M18",
          stop_name: "Delancey St-Essex St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M19",
          stop_name: "Bowery",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M20",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M21",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M22",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M23",
          stop_name: "Broad St",
        },
      ],
    },
    {
      line: "Z",
      stops: [
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G05",
          stop_name: "Jamaica Center-Parsons/Archer",
        },
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G06",
          stop_name: "Sutphin Blvd-Archer Av-JFK Airport",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J12",
          stop_name: "121 St",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J14",
          stop_name: "104 St",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J15",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J17",
          stop_name: "75 St-Elderts Ln",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J20",
          stop_name: "Crescent St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J21",
          stop_name: "Norwood Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J23",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J24",
          stop_name: "Alabama Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J27",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J28",
          stop_name: "Chauncey St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J30",
          stop_name: "Gates Av",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M11",
          stop_name: "Myrtle Av",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M16",
          stop_name: "Marcy Av",
        },
        {
          borough: "Manhattan",
          service: "JMZ",
          stop_id: "M18",
          stop_name: "Delancey St-Essex St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M19",
          stop_name: "Bowery",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M20",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M21",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M22",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M23",
          stop_name: "Broad St",
        },
      ],
    },
    {
      line: "N",
      stops: [
        {
          borough: "Brooklyn",
          service: "DFNQ",
          stop_id: "D43",
          stop_name: "Coney Island-Stillwell Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N02",
          stop_name: "8 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N03",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N04",
          stop_name: "New Utrecht Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N05",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N06",
          stop_name: "20 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N07",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N08",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N09",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N10",
          stop_name: "86 St",
        },
        {
          borough: "Brooklyn",
          service: "NQ",
          stop_id: "N12",
          stop_name: "S.B. Coney Island",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q03",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q04",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q05",
          stop_name: "96 St",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R01",
          stop_name: "Astoria-Ditmars Blvd",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R03",
          stop_name: "Astoria Blvd",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R04",
          stop_name: "30 Av",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R05",
          stop_name: "Broadway",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R06",
          stop_name: "36 Av",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R08",
          stop_name: "39 Av-Dutch Kills",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R09",
          stop_name: "Queensboro Plaza",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R11",
          stop_name: "Lexington Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R13",
          stop_name: "5 Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R14",
          stop_name: "57 St-7 Av",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R15",
          stop_name: "49 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R16",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R18",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R19",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R20",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R21",
          stop_name: "8 St-NYU",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R22",
          stop_name: "Prince St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R23",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R24",
          stop_name: "City Hall",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R25",
          stop_name: "Cortlandt St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R26",
          stop_name: "Rector St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R27",
          stop_name: "Whitehall St-South Ferry",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R28",
          stop_name: "Court St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R29",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R31",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R32",
          stop_name: "Union St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R33",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R34",
          stop_name: "Prospect Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R35",
          stop_name: "25 St",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R39",
          stop_name: "45 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R40",
          stop_name: "53 St",
        },
        {
          borough: "Brooklyn",
          service: "NQRW",
          stop_id: "R41",
          stop_name: "59 St",
        },
      ],
    },
    {
      line: "Q",
      stops: [
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D24",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D25",
          stop_name: "7 Av (B)",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D26",
          stop_name: "Prospect Park",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D27",
          stop_name: "Parkside Av",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D28",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D29",
          stop_name: "Beverley Rd",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D30",
          stop_name: "Cortelyou Rd",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D31",
          stop_name: "Newkirk Plaza",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D32",
          stop_name: "Avenue H",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D33",
          stop_name: "Avenue J",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D34",
          stop_name: "Avenue M",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D35",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D37",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D38",
          stop_name: "Neck Rd",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D39",
          stop_name: "Sheepshead Bay",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D40",
          stop_name: "Brighton Beach",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D41",
          stop_name: "Ocean Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "FQ",
          stop_id: "D42",
          stop_name: "W 8 St-NY Aquarium",
        },
        {
          borough: "Brooklyn",
          service: "DFNQ",
          stop_id: "D43",
          stop_name: "Coney Island-Stillwell Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N02",
          stop_name: "8 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N03",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N04",
          stop_name: "New Utrecht Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N05",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N06",
          stop_name: "20 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N07",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N08",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N09",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N10",
          stop_name: "86 St",
        },
        {
          borough: "Brooklyn",
          service: "NQ",
          stop_id: "N12",
          stop_name: "S.B. Coney Island",
        },
        {
          borough: "Manhattan",
          service: "Q",
          stop_id: "Q01",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q03",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q04",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q05",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R14",
          stop_name: "57 St-7 Av",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R15",
          stop_name: "49 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R16",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R18",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R19",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R20",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R21",
          stop_name: "8 St-NYU",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R22",
          stop_name: "Prince St",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
        {
          borough: "Brooklyn",
          service: "NQRW",
          stop_id: "R41",
          stop_name: "59 St",
        },
      ],
    },
    {
      line: "R",
      stops: [
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G08",
          stop_name: "Forest Hills-71 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G09",
          stop_name: "67 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G10",
          stop_name: "63 Dr-Rego Park",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G11",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G12",
          stop_name: "Grand Av-Newtown",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G13",
          stop_name: "Elmhurst Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G14",
          stop_name: "Jackson Hts-Roosevelt Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G15",
          stop_name: "65 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G16",
          stop_name: "Northern Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G18",
          stop_name: "46 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G19",
          stop_name: "Steinway St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G20",
          stop_name: "36 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G21",
          stop_name: "Queens Plaza",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q03",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q04",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q05",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R11",
          stop_name: "Lexington Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R13",
          stop_name: "5 Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R14",
          stop_name: "57 St-7 Av",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R15",
          stop_name: "49 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R16",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R18",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R19",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R20",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R21",
          stop_name: "8 St-NYU",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R22",
          stop_name: "Prince St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R23",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R24",
          stop_name: "City Hall",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R25",
          stop_name: "Cortlandt St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R26",
          stop_name: "Rector St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R27",
          stop_name: "Whitehall St-South Ferry",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R28",
          stop_name: "Court St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R29",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R31",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R32",
          stop_name: "Union St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R33",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R34",
          stop_name: "Prospect Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R35",
          stop_name: "25 St",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R39",
          stop_name: "45 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R40",
          stop_name: "53 St",
        },
        {
          borough: "Brooklyn",
          service: "NQRW",
          stop_id: "R41",
          stop_name: "59 St",
        },
        {
          borough: "Brooklyn",
          service: "R",
          stop_id: "R42",
          stop_name: "Bay Ridge Av",
        },
        {
          borough: "Brooklyn",
          service: "R",
          stop_id: "R43",
          stop_name: "77 St",
        },
        {
          borough: "Brooklyn",
          service: "R",
          stop_id: "R44",
          stop_name: "86 St",
        },
        {
          borough: "Brooklyn",
          service: "R",
          stop_id: "R45",
          stop_name: "Bay Ridge-95 St",
        },
      ],
    },
    {
      line: "W",
      stops: [
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N02",
          stop_name: "8 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N03",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N04",
          stop_name: "New Utrecht Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N05",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N06",
          stop_name: "20 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N07",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N08",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N09",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N10",
          stop_name: "86 St",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R01",
          stop_name: "Astoria-Ditmars Blvd",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R03",
          stop_name: "Astoria Blvd",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R04",
          stop_name: "30 Av",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R05",
          stop_name: "Broadway",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R06",
          stop_name: "36 Av",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R08",
          stop_name: "39 Av-Dutch Kills",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R09",
          stop_name: "Queensboro Plaza",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R11",
          stop_name: "Lexington Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R13",
          stop_name: "5 Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R14",
          stop_name: "57 St-7 Av",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R15",
          stop_name: "49 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R16",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R18",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R19",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R20",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R21",
          stop_name: "8 St-NYU",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R22",
          stop_name: "Prince St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R23",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R24",
          stop_name: "City Hall",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R25",
          stop_name: "Cortlandt St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R26",
          stop_name: "Rector St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R27",
          stop_name: "Whitehall St-South Ferry",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R28",
          stop_name: "Court St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R29",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R31",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R32",
          stop_name: "Union St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R33",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R34",
          stop_name: "Prospect Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R35",
          stop_name: "25 St",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R39",
          stop_name: "45 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R40",
          stop_name: "53 St",
        },
        {
          borough: "Brooklyn",
          service: "NQRW",
          stop_id: "R41",
          stop_name: "59 St",
        },
      ],
    },
    {
      line: "L",
      stops: [
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L01",
          stop_name: "8 Av",
        },
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L02",
          stop_name: "6 Av",
        },
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L03",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L05",
          stop_name: "3 Av",
        },
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L06",
          stop_name: "1 Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L08",
          stop_name: "Bedford Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L10",
          stop_name: "Lorimer St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L11",
          stop_name: "Graham Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L12",
          stop_name: "Grand St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L13",
          stop_name: "Montrose Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L14",
          stop_name: "Morgan Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L15",
          stop_name: "Jefferson St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L16",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L17",
          stop_name: "Myrtle-Wyckoff Avs",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L19",
          stop_name: "Halsey St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L20",
          stop_name: "Wilson Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L21",
          stop_name: "Bushwick Av-Aberdeen St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L22",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L24",
          stop_name: "Atlantic Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L25",
          stop_name: "Sutter Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L26",
          stop_name: "Livonia Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L27",
          stop_name: "New Lots Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L28",
          stop_name: "East 105 St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L29",
          stop_name: "Canarsie-Rockaway Pkwy",
        },
      ],
    },
    {
      line: "S",
      stops: [
        {
          borough: "Manhattan",
          service: "S",
          stop_id: "901",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "S",
          stop_id: "902",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H04",
          stop_name: "Broad Channel",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H12",
          stop_name: "Beach 90 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H13",
          stop_name: "Beach 98 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H14",
          stop_name: "Beach 105 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H15",
          stop_name: "Rockaway Park-Beach 116 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H19",
          stop_name: "Broad Channel",
        },
        {
          borough: "Brooklyn",
          service: "S",
          stop_id: "S01",
          stop_name: "Franklin Av",
        },
        {
          borough: "Brooklyn",
          service: "S",
          stop_id: "S03",
          stop_name: "Park Pl",
        },
        {
          borough: "Brooklyn",
          service: "S",
          stop_id: "S04",
          stop_name: "Botanic Garden",
        },
      ],
    },
  ];
  return stops.filter((stop) => stop.line === line)[0]["stops"];
};

const getStopService = (stopId) => {
  const lines = [
    {
      line: "1",
      stops: [
        {
          borough: "Bronx",
          service: "1",
          stop_id: "101",
          stop_name: "Van Cortlandt Park-242 St",
        },
        {
          borough: "Bronx",
          service: "1",
          stop_id: "103",
          stop_name: "238 St",
        },
        {
          borough: "Bronx",
          service: "1",
          stop_id: "104",
          stop_name: "231 St",
        },
        {
          borough: "Bronx",
          service: "1",
          stop_id: "106",
          stop_name: "Marble Hill-225 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "107",
          stop_name: "215 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "108",
          stop_name: "207 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "109",
          stop_name: "Dyckman St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "110",
          stop_name: "191 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "111",
          stop_name: "181 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "112",
          stop_name: "168 St-Washington Hts",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "113",
          stop_name: "157 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "114",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "115",
          stop_name: "137 St-City College",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "116",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "117",
          stop_name: "116 St-Columbia University",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "118",
          stop_name: "Cathedral Pkwy (110 St)",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "119",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "120",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "121",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "122",
          stop_name: "79 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "123",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "124",
          stop_name: "66 St-Lincoln Center",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "125",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "126",
          stop_name: "50 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "127",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "128",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "129",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "130",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "131",
          stop_name: "18 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "132",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "133",
          stop_name: "Christopher St-Sheridan Sq",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "134",
          stop_name: "Houston St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "135",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "136",
          stop_name: "Franklin St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "137",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "138",
          stop_name: "WTC Cortlandt",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "139",
          stop_name: "Rector St",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "140",
          stop_name: "South Ferry Loop",
        },
        {
          borough: "Manhattan",
          service: "1",
          stop_id: "142",
          stop_name: "South Ferry",
        },
      ],
    },
    {
      line: "2",
      stops: [
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "120",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "121",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "122",
          stop_name: "79 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "123",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "124",
          stop_name: "66 St-Lincoln Center",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "125",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "126",
          stop_name: "50 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "127",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "128",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "129",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "130",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "131",
          stop_name: "18 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "132",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "133",
          stop_name: "Christopher St-Sheridan Sq",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "134",
          stop_name: "Houston St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "135",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "12",
          stop_id: "136",
          stop_name: "Franklin St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "137",
          stop_name: "Chambers St",
        },
        {
          borough: "Bronx",
          service: "2",
          stop_id: "201",
          stop_name: "Wakefield-241 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "204",
          stop_name: "Nereid Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "205",
          stop_name: "233 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "206",
          stop_name: "225 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "207",
          stop_name: "219 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "208",
          stop_name: "Gun Hill Rd",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "209",
          stop_name: "Burke Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "210",
          stop_name: "Allerton Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "211",
          stop_name: "Pelham Pkwy",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "212",
          stop_name: "Bronx Park East",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "213",
          stop_name: "E 180 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "214",
          stop_name: "West Farms Sq-E Tremont Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "215",
          stop_name: "174 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "216",
          stop_name: "Freeman St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "217",
          stop_name: "Simpson St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "218",
          stop_name: "Intervale Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "219",
          stop_name: "Prospect Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "220",
          stop_name: "Jackson Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "221",
          stop_name: "3 Av-149 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "222",
          stop_name: "149 St-Grand Concourse",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "224",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "225",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "226",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "227",
          stop_name: "Central Park North (110 St)",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "228",
          stop_name: "Park Place",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "229",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "230",
          stop_name: "Wall St",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "231",
          stop_name: "Clark St",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "232",
          stop_name: "Borough Hall",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "233",
          stop_name: "Hoyt St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "234",
          stop_name: "Nevins St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "235",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "236",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "237",
          stop_name: "Grand Army Plaza",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "238",
          stop_name: "Eastern Pkwy-Brooklyn Museum",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "239",
          stop_name: "Franklin Av-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "241",
          stop_name: "President St-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "242",
          stop_name: "Sterling St",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "243",
          stop_name: "Winthrop St",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "244",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "245",
          stop_name: "Beverly Rd",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "246",
          stop_name: "Newkirk Av-Little Haiti",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "247",
          stop_name: "Flatbush Av-Brooklyn College",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "248",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "249",
          stop_name: "Kingston Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "250",
          stop_name: "Crown Hts-Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "251",
          stop_name: "Sutter Av-Rutland Rd",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "252",
          stop_name: "Saratoga Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "253",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "254",
          stop_name: "Junius St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "255",
          stop_name: "Pennsylvania Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "256",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "257",
          stop_name: "New Lots Av",
        },
      ],
    },
    {
      line: "3",
      stops: [
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "120",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "123",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "127",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "128",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "132",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "123",
          stop_id: "137",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "224",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "225",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "226",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "227",
          stop_name: "Central Park North (110 St)",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "228",
          stop_name: "Park Place",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "229",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "23",
          stop_id: "230",
          stop_name: "Wall St",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "231",
          stop_name: "Clark St",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "232",
          stop_name: "Borough Hall",
        },
        {
          borough: "Brooklyn",
          service: "23",
          stop_id: "233",
          stop_name: "Hoyt St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "234",
          stop_name: "Nevins St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "235",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "236",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "237",
          stop_name: "Grand Army Plaza",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "238",
          stop_name: "Eastern Pkwy-Brooklyn Museum",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "239",
          stop_name: "Franklin Av-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "248",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "249",
          stop_name: "Kingston Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "250",
          stop_name: "Crown Hts-Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "251",
          stop_name: "Sutter Av-Rutland Rd",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "252",
          stop_name: "Saratoga Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "253",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "254",
          stop_name: "Junius St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "255",
          stop_name: "Pennsylvania Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "256",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "257",
          stop_name: "New Lots Av",
        },
        {
          borough: "Manhattan",
          service: "3",
          stop_id: "301",
          stop_name: "Harlem-148 St",
        },
        {
          borough: "Manhattan",
          service: "3",
          stop_id: "302",
          stop_name: "145 St",
        },
      ],
    },
    {
      line: "4",
      stops: [
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "234",
          stop_name: "Nevins St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "235",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "236",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "237",
          stop_name: "Grand Army Plaza",
        },
        {
          borough: "Brooklyn",
          service: "234",
          stop_id: "238",
          stop_name: "Eastern Pkwy-Brooklyn Museum",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "239",
          stop_name: "Franklin Av-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "248",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "249",
          stop_name: "Kingston Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "250",
          stop_name: "Crown Hts-Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "251",
          stop_name: "Sutter Av-Rutland Rd",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "252",
          stop_name: "Saratoga Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "253",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "254",
          stop_name: "Junius St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "255",
          stop_name: "Pennsylvania Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "256",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "257",
          stop_name: "New Lots Av",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "401",
          stop_name: "Woodlawn",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "402",
          stop_name: "Mosholu Pkwy",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "405",
          stop_name: "Bedford Park Blvd-Lehman College",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "406",
          stop_name: "Kingsbridge Rd",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "407",
          stop_name: "Fordham Rd",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "408",
          stop_name: "183 St",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "409",
          stop_name: "Burnside Av",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "410",
          stop_name: "176 St",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "411",
          stop_name: "Mt Eden Av",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "412",
          stop_name: "170 St",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "413",
          stop_name: "167 St",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "414",
          stop_name: "161 St-Yankee Stadium",
        },
        {
          borough: "Bronx",
          service: "4",
          stop_id: "415",
          stop_name: "149 St-Grand Concourse",
        },
        {
          borough: "Bronx",
          service: "45",
          stop_id: "416",
          stop_name: "138 St-Grand Concourse",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "418",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "419",
          stop_name: "Wall St",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "420",
          stop_name: "Bowling Green",
        },
        {
          borough: "Brooklyn",
          service: "45",
          stop_id: "423",
          stop_name: "Borough Hall",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "621",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "622",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "623",
          stop_name: "110 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "624",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "625",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "626",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "627",
          stop_name: "77 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "628",
          stop_name: "68 St-Hunter College",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "629",
          stop_name: "59 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "630",
          stop_name: "51 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "631",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "632",
          stop_name: "33 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "633",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "634",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "635",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "636",
          stop_name: "Astor Pl",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "637",
          stop_name: "Bleecker St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "638",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "639",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "640",
          stop_name: "Brooklyn Bridge-City Hall",
        },
      ],
    },
    {
      line: "5",
      stops: [
        {
          borough: "Bronx",
          service: "25",
          stop_id: "204",
          stop_name: "Nereid Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "205",
          stop_name: "233 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "206",
          stop_name: "225 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "207",
          stop_name: "219 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "208",
          stop_name: "Gun Hill Rd",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "209",
          stop_name: "Burke Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "210",
          stop_name: "Allerton Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "211",
          stop_name: "Pelham Pkwy",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "212",
          stop_name: "Bronx Park East",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "213",
          stop_name: "E 180 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "214",
          stop_name: "West Farms Sq-E Tremont Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "215",
          stop_name: "174 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "216",
          stop_name: "Freeman St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "217",
          stop_name: "Simpson St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "218",
          stop_name: "Intervale Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "219",
          stop_name: "Prospect Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "220",
          stop_name: "Jackson Av",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "221",
          stop_name: "3 Av-149 St",
        },
        {
          borough: "Bronx",
          service: "25",
          stop_id: "222",
          stop_name: "149 St-Grand Concourse",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "234",
          stop_name: "Nevins St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "235",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "239",
          stop_name: "Franklin Av-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "241",
          stop_name: "President St-Medgar Evers College",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "242",
          stop_name: "Sterling St",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "243",
          stop_name: "Winthrop St",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "244",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "245",
          stop_name: "Beverly Rd",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "246",
          stop_name: "Newkirk Av-Little Haiti",
        },
        {
          borough: "Brooklyn",
          service: "25",
          stop_id: "247",
          stop_name: "Flatbush Av-Brooklyn College",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "248",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "249",
          stop_name: "Kingston Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "250",
          stop_name: "Crown Hts-Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "251",
          stop_name: "Sutter Av-Rutland Rd",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "252",
          stop_name: "Saratoga Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "253",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "254",
          stop_name: "Junius St",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "255",
          stop_name: "Pennsylvania Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "256",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "2345",
          stop_id: "257",
          stop_name: "New Lots Av",
        },
        {
          borough: "Bronx",
          service: "45",
          stop_id: "416",
          stop_name: "138 St-Grand Concourse",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "418",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "419",
          stop_name: "Wall St",
        },
        {
          borough: "Manhattan",
          service: "45",
          stop_id: "420",
          stop_name: "Bowling Green",
        },
        {
          borough: "Brooklyn",
          service: "45",
          stop_id: "423",
          stop_name: "Borough Hall",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "501",
          stop_name: "Eastchester-Dyre Av",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "502",
          stop_name: "Baychester Av",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "503",
          stop_name: "Gun Hill Rd",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "504",
          stop_name: "Pelham Pkwy",
        },
        {
          borough: "Bronx",
          service: "5",
          stop_id: "505",
          stop_name: "Morris Park",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "621",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "626",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "629",
          stop_name: "59 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "631",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "635",
          stop_name: "14 St-Union Sq",
        },
      ],
    },
    {
      line: "6",
      stops: [
        {
          borough: "Bronx",
          service: "6",
          stop_id: "601",
          stop_name: "Pelham Bay Park",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "602",
          stop_name: "Buhre Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "603",
          stop_name: "Middletown Rd",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "604",
          stop_name: "Westchester Sq-E Tremont Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "606",
          stop_name: "Zerega Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "607",
          stop_name: "Castle Hill Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "608",
          stop_name: "Parkchester",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "609",
          stop_name: "St Lawrence Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "610",
          stop_name: "Morrison Av-Soundview",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "611",
          stop_name: "Elder Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "612",
          stop_name: "Whitlock Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "613",
          stop_name: "Hunts Point Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "614",
          stop_name: "Longwood Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "615",
          stop_name: "E 149 St",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "616",
          stop_name: "E 143 St-St Mary's St",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "617",
          stop_name: "Cypress Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "618",
          stop_name: "Brook Av",
        },
        {
          borough: "Bronx",
          service: "6",
          stop_id: "619",
          stop_name: "3 Av-138 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "621",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "622",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "623",
          stop_name: "110 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "624",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "625",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "626",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "627",
          stop_name: "77 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "628",
          stop_name: "68 St-Hunter College",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "629",
          stop_name: "59 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "630",
          stop_name: "51 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "631",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "632",
          stop_name: "33 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "633",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "634",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "456",
          stop_id: "635",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "636",
          stop_name: "Astor Pl",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "637",
          stop_name: "Bleecker St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "638",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "639",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "46",
          stop_id: "640",
          stop_name: "Brooklyn Bridge-City Hall",
        },
      ],
    },
    {
      line: "A",
      stops: [
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A02",
          stop_name: "Inwood-207 St",
        },
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A03",
          stop_name: "Dyckman St",
        },
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A05",
          stop_name: "190 St",
        },
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A06",
          stop_name: "181 St",
        },
        {
          borough: "Manhattan",
          service: "A",
          stop_id: "A07",
          stop_name: "175 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A09",
          stop_name: "168 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A10",
          stop_name: "163 St-Amsterdam Av",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A11",
          stop_name: "155 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A12",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A14",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A15",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A16",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A17",
          stop_name: "Cathedral Pkwy (110 St)",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A18",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A19",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A20",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A21",
          stop_name: "81 St-Museum of Natural History",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A22",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A24",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A25",
          stop_name: "50 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A27",
          stop_name: "42 St-Port Authority Bus Terminal",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A28",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A30",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A31",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A32",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A33",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A34",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A36",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A38",
          stop_name: "Fulton St",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A40",
          stop_name: "High St",
        },
        {
          borough: "Brooklyn",
          service: "ACF",
          stop_id: "A41",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "ACG",
          stop_id: "A42",
          stop_name: "Hoyt-Schermerhorn Sts",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A43",
          stop_name: "Lafayette Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A44",
          stop_name: "Clinton-Washington Avs",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A45",
          stop_name: "Franklin Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A46",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A47",
          stop_name: "Kingston-Throop Avs",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A48",
          stop_name: "Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A49",
          stop_name: "Ralph Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A50",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A51",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A52",
          stop_name: "Liberty Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A53",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A54",
          stop_name: "Shepherd Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A55",
          stop_name: "Euclid Av",
        },
        {
          borough: "Brooklyn",
          service: "A",
          stop_id: "A57",
          stop_name: "Grant Av",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A59",
          stop_name: "80 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A60",
          stop_name: "88 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A61",
          stop_name: "Rockaway Blvd",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A63",
          stop_name: "104 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A64",
          stop_name: "111 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "A65",
          stop_name: "Ozone Park-Lefferts Blvd",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H01",
          stop_name: "Aqueduct Racetrack",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H02",
          stop_name: "Aqueduct-N Conduit Av",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H03",
          stop_name: "Howard Beach-JFK Airport",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H04",
          stop_name: "Broad Channel",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H06",
          stop_name: "Beach 67 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H07",
          stop_name: "Beach 60 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H08",
          stop_name: "Beach 44 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H09",
          stop_name: "Beach 36 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H10",
          stop_name: "Beach 25 St",
        },
        {
          borough: "Queens",
          service: "A",
          stop_id: "H11",
          stop_name: "Far Rockaway-Mott Av",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H12",
          stop_name: "Beach 90 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H13",
          stop_name: "Beach 98 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H14",
          stop_name: "Beach 105 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H15",
          stop_name: "Rockaway Park-Beach 116 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H19",
          stop_name: "Broad Channel",
        },
      ],
    },
    {
      line: "C",
      stops: [
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A09",
          stop_name: "168 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A10",
          stop_name: "163 St-Amsterdam Av",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A11",
          stop_name: "155 St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A12",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A14",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A15",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A16",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A17",
          stop_name: "Cathedral Pkwy (110 St)",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A18",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A19",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A20",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A21",
          stop_name: "81 St-Museum of Natural History",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A22",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A24",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A25",
          stop_name: "50 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A27",
          stop_name: "42 St-Port Authority Bus Terminal",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A28",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A30",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A31",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A32",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A33",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A34",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A36",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "AC",
          stop_id: "A38",
          stop_name: "Fulton St",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A40",
          stop_name: "High St",
        },
        {
          borough: "Brooklyn",
          service: "ACF",
          stop_id: "A41",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "ACG",
          stop_id: "A42",
          stop_name: "Hoyt-Schermerhorn Sts",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A43",
          stop_name: "Lafayette Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A44",
          stop_name: "Clinton-Washington Avs",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A45",
          stop_name: "Franklin Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A46",
          stop_name: "Nostrand Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A47",
          stop_name: "Kingston-Throop Avs",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A48",
          stop_name: "Utica Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A49",
          stop_name: "Ralph Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A50",
          stop_name: "Rockaway Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A51",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A52",
          stop_name: "Liberty Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A53",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A54",
          stop_name: "Shepherd Av",
        },
        {
          borough: "Brooklyn",
          service: "AC",
          stop_id: "A55",
          stop_name: "Euclid Av",
        },
      ],
    },
    {
      line: "E",
      stops: [
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A27",
          stop_name: "42 St-Port Authority Bus Terminal",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A28",
          stop_name: "34 St-Penn Station",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A30",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A31",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A32",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A33",
          stop_name: "Spring St",
        },
        {
          borough: "Manhattan",
          service: "ACE",
          stop_id: "A34",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "BDE",
          stop_id: "D14",
          stop_name: "7 Av (M)",
        },
        {
          borough: "Manhattan",
          service: "E",
          stop_id: "E01",
          stop_name: "World Trade Center",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F01",
          stop_name: "Jamaica-179 St",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F02",
          stop_name: "169 St",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F03",
          stop_name: "Parsons Blvd",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F04",
          stop_name: "Sutphin Blvd",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F05",
          stop_name: "Briarwood",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F06",
          stop_name: "Kew Gardens-Union Tpke",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F07",
          stop_name: "75 Av",
        },
        {
          borough: "Queens",
          service: "EM",
          stop_id: "F09",
          stop_name: "Court Sq-23 St",
        },
        {
          borough: "Manhattan",
          service: "EM",
          stop_id: "F11",
          stop_name: "Lexington Av/53 St",
        },
        {
          borough: "Manhattan",
          service: "EM",
          stop_id: "F12",
          stop_name: "5 Av/53 St",
        },
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G05",
          stop_name: "Jamaica Center-Parsons/Archer",
        },
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G06",
          stop_name: "Sutphin Blvd-Archer Av-JFK Airport",
        },
        {
          borough: "Queens",
          service: "E",
          stop_id: "G07",
          stop_name: "Jamaica-Van Wyck",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G08",
          stop_name: "Forest Hills-71 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G09",
          stop_name: "67 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G10",
          stop_name: "63 Dr-Rego Park",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G11",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G12",
          stop_name: "Grand Av-Newtown",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G13",
          stop_name: "Elmhurst Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G14",
          stop_name: "Jackson Hts-Roosevelt Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G15",
          stop_name: "65 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G16",
          stop_name: "Northern Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G18",
          stop_name: "46 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G19",
          stop_name: "Steinway St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G20",
          stop_name: "36 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G21",
          stop_name: "Queens Plaza",
        },
      ],
    },
    {
      line: "G",
      stops: [
        {
          borough: "Brooklyn",
          service: "ACG",
          stop_id: "A42",
          stop_name: "Hoyt-Schermerhorn Sts",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F20",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F21",
          stop_name: "Carroll St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F22",
          stop_name: "Smith-9 Sts",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F23",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F24",
          stop_name: "7 Av",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F25",
          stop_name: "15 St-Prospect Park",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F26",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F27",
          stop_name: "Church Av",
        },
        {
          borough: "Queens",
          service: "G",
          stop_id: "G22",
          stop_name: "Court Sq",
        },
        {
          borough: "Queens",
          service: "G",
          stop_id: "G24",
          stop_name: "21 St",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G26",
          stop_name: "Greenpoint Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G28",
          stop_name: "Nassau Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G29",
          stop_name: "Metropolitan Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G30",
          stop_name: "Broadway",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G31",
          stop_name: "Flushing Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G32",
          stop_name: "Myrtle-Willoughby Avs",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G33",
          stop_name: "Bedford-Nostrand Avs",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G34",
          stop_name: "Classon Av",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G35",
          stop_name: "Clinton-Washington Avs",
        },
        {
          borough: "Brooklyn",
          service: "G",
          stop_id: "G36",
          stop_name: "Fulton St",
        },
      ],
    },
    {
      line: "7",
      stops: [
        {
          borough: "Queens",
          service: "7",
          stop_id: "701",
          stop_name: "Flushing-Main St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "702",
          stop_name: "Mets-Willets Point",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "705",
          stop_name: "111 St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "706",
          stop_name: "103 St-Corona Plaza",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "707",
          stop_name: "Junction Blvd",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "708",
          stop_name: "90 St-Elmhurst Av",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "709",
          stop_name: "82 St-Jackson Hts",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "710",
          stop_name: "74 St-Broadway",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "711",
          stop_name: "69 St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "712",
          stop_name: "61 St-Woodside",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "713",
          stop_name: "52 St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "714",
          stop_name: "46 St-Bliss St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "715",
          stop_name: "40 St-Lowery St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "716",
          stop_name: "33 St-Rawson St",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "718",
          stop_name: "Queensboro Plaza",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "719",
          stop_name: "Court Sq",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "720",
          stop_name: "Hunters Point Av",
        },
        {
          borough: "Queens",
          service: "7",
          stop_id: "721",
          stop_name: "Vernon Blvd-Jackson Av",
        },
        {
          borough: "Manhattan",
          service: "7",
          stop_id: "723",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "7",
          stop_id: "724",
          stop_name: "5 Av",
        },
        {
          borough: "Manhattan",
          service: "7",
          stop_id: "725",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "7",
          stop_id: "726",
          stop_name: "34 St-Hudson Yards",
        },
      ],
    },
    {
      line: "B",
      stops: [
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A14",
          stop_name: "135 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A15",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A16",
          stop_name: "116 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A17",
          stop_name: "Cathedral Pkwy (110 St)",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A18",
          stop_name: "103 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A19",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A20",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A21",
          stop_name: "81 St-Museum of Natural History",
        },
        {
          borough: "Manhattan",
          service: "ABC",
          stop_id: "A22",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A24",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D03",
          stop_name: "Bedford Park Blvd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D04",
          stop_name: "Kingsbridge Rd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D05",
          stop_name: "Fordham Rd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D06",
          stop_name: "182-183 Sts",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D07",
          stop_name: "Tremont Av",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D08",
          stop_name: "174-175 Sts",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D09",
          stop_name: "170 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D10",
          stop_name: "167 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D11",
          stop_name: "161 St-Yankee Stadium",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D12",
          stop_name: "155 St",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D13",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "BDE",
          stop_id: "D14",
          stop_name: "7 Av (M)",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D15",
          stop_name: "47-50 Sts-Rockefeller Ctr",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D16",
          stop_name: "42 St-Bryant Pk",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D20",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D21",
          stop_name: "Broadway-Lafayette St",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D22",
          stop_name: "Grand St",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D24",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D25",
          stop_name: "7 Av (B)",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D26",
          stop_name: "Prospect Park",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D28",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D31",
          stop_name: "Newkirk Plaza",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D35",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D39",
          stop_name: "Sheepshead Bay",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D40",
          stop_name: "Brighton Beach",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
      ],
    },
    {
      line: "D",
      stops: [
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A15",
          stop_name: "125 St",
        },
        {
          borough: "Manhattan",
          service: "ABCD",
          stop_id: "A24",
          stop_name: "59 St-Columbus Circle",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B12",
          stop_name: "9 Av",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B13",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B14",
          stop_name: "50 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B15",
          stop_name: "55 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B16",
          stop_name: "62 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B17",
          stop_name: "71 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B18",
          stop_name: "79 St",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B19",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B20",
          stop_name: "20 Av",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B21",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B22",
          stop_name: "25 Av",
        },
        {
          borough: "Brooklyn",
          service: "D",
          stop_id: "B23",
          stop_name: "Bay 50 St",
        },
        {
          borough: "Bronx",
          service: "D",
          stop_id: "D01",
          stop_name: "Norwood-205 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D03",
          stop_name: "Bedford Park Blvd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D04",
          stop_name: "Kingsbridge Rd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D05",
          stop_name: "Fordham Rd",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D06",
          stop_name: "182-183 Sts",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D07",
          stop_name: "Tremont Av",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D08",
          stop_name: "174-175 Sts",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D09",
          stop_name: "170 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D10",
          stop_name: "167 St",
        },
        {
          borough: "Bronx",
          service: "BD",
          stop_id: "D11",
          stop_name: "161 St-Yankee Stadium",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D12",
          stop_name: "155 St",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D13",
          stop_name: "145 St",
        },
        {
          borough: "Manhattan",
          service: "BDE",
          stop_id: "D14",
          stop_name: "7 Av (M)",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D15",
          stop_name: "47-50 Sts-Rockefeller Ctr",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D16",
          stop_name: "42 St-Bryant Pk",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D20",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D21",
          stop_name: "Broadway-Lafayette St",
        },
        {
          borough: "Manhattan",
          service: "BD",
          stop_id: "D22",
          stop_name: "Grand St",
        },
        {
          borough: "Brooklyn",
          service: "DFNQ",
          stop_id: "D43",
          stop_name: "Coney Island-Stillwell Av",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R31",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R32",
          stop_name: "Union St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R33",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R34",
          stop_name: "Prospect Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R35",
          stop_name: "25 St",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
      ],
    },
    {
      line: "F",
      stops: [
        {
          borough: "Brooklyn",
          service: "ACF",
          stop_id: "A41",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Queens",
          service: "F",
          stop_id: "B04",
          stop_name: "21 St-Queensbridge",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "B06",
          stop_name: "Roosevelt Island",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "B08",
          stop_name: "Lexington Av/63 St",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "B10",
          stop_name: "57 St",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D15",
          stop_name: "47-50 Sts-Rockefeller Ctr",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D16",
          stop_name: "42 St-Bryant Pk",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "FM",
          stop_id: "D18",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "FM",
          stop_id: "D19",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D20",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D21",
          stop_name: "Broadway-Lafayette St",
        },
        {
          borough: "Brooklyn",
          service: "FQ",
          stop_id: "D42",
          stop_name: "W 8 St-NY Aquarium",
        },
        {
          borough: "Brooklyn",
          service: "DFNQ",
          stop_id: "D43",
          stop_name: "Coney Island-Stillwell Av",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F01",
          stop_name: "Jamaica-179 St",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F02",
          stop_name: "169 St",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F03",
          stop_name: "Parsons Blvd",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F04",
          stop_name: "Sutphin Blvd",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F05",
          stop_name: "Briarwood",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F06",
          stop_name: "Kew Gardens-Union Tpke",
        },
        {
          borough: "Queens",
          service: "EF",
          stop_id: "F07",
          stop_name: "75 Av",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "F14",
          stop_name: "2 Av",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "F15",
          stop_name: "Delancey St-Essex St",
        },
        {
          borough: "Manhattan",
          service: "F",
          stop_id: "F16",
          stop_name: "East Broadway",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F18",
          stop_name: "York St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F20",
          stop_name: "Bergen St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F21",
          stop_name: "Carroll St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F22",
          stop_name: "Smith-9 Sts",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F23",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F24",
          stop_name: "7 Av",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F25",
          stop_name: "15 St-Prospect Park",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F26",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "FG",
          stop_id: "F27",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F29",
          stop_name: "Ditmas Av",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F30",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F31",
          stop_name: "Avenue I",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F32",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F33",
          stop_name: "Avenue N",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F34",
          stop_name: "Avenue P",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F35",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F36",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F38",
          stop_name: "Avenue X",
        },
        {
          borough: "Brooklyn",
          service: "F",
          stop_id: "F39",
          stop_name: "Neptune Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G08",
          stop_name: "Forest Hills-71 Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G14",
          stop_name: "Jackson Hts-Roosevelt Av",
        },
      ],
    },
    {
      line: "M",
      stops: [
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D15",
          stop_name: "47-50 Sts-Rockefeller Ctr",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D16",
          stop_name: "42 St-Bryant Pk",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "FM",
          stop_id: "D18",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "FM",
          stop_id: "D19",
          stop_name: "14 St",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D20",
          stop_name: "W 4 St-Wash Sq",
        },
        {
          borough: "Manhattan",
          service: "BDFM",
          stop_id: "D21",
          stop_name: "Broadway-Lafayette St",
        },
        {
          borough: "Queens",
          service: "EM",
          stop_id: "F09",
          stop_name: "Court Sq-23 St",
        },
        {
          borough: "Manhattan",
          service: "EM",
          stop_id: "F11",
          stop_name: "Lexington Av/53 St",
        },
        {
          borough: "Manhattan",
          service: "EM",
          stop_id: "F12",
          stop_name: "5 Av/53 St",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G08",
          stop_name: "Forest Hills-71 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G09",
          stop_name: "67 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G10",
          stop_name: "63 Dr-Rego Park",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G11",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G12",
          stop_name: "Grand Av-Newtown",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G13",
          stop_name: "Elmhurst Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G14",
          stop_name: "Jackson Hts-Roosevelt Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G15",
          stop_name: "65 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G16",
          stop_name: "Northern Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G18",
          stop_name: "46 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G19",
          stop_name: "Steinway St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G20",
          stop_name: "36 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G21",
          stop_name: "Queens Plaza",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M01",
          stop_name: "Middle Village-Metropolitan Av",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M04",
          stop_name: "Fresh Pond Rd",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M05",
          stop_name: "Forest Av",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M06",
          stop_name: "Seneca Av",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M08",
          stop_name: "Myrtle-Wyckoff Avs",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M09",
          stop_name: "Knickerbocker Av",
        },
        {
          borough: "Brooklyn",
          service: "M",
          stop_id: "M10",
          stop_name: "Central Av",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M11",
          stop_name: "Myrtle Av",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M12",
          stop_name: "Flushing Av",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M13",
          stop_name: "Lorimer St",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M14",
          stop_name: "Hewes St",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M16",
          stop_name: "Marcy Av",
        },
        {
          borough: "Manhattan",
          service: "JMZ",
          stop_id: "M18",
          stop_name: "Delancey St-Essex St",
        },
      ],
    },
    {
      line: "J",
      stops: [
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G05",
          stop_name: "Jamaica Center-Parsons/Archer",
        },
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G06",
          stop_name: "Sutphin Blvd-Archer Av-JFK Airport",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J12",
          stop_name: "121 St",
        },
        {
          borough: "Queens",
          service: "J",
          stop_id: "J13",
          stop_name: "111 St",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J14",
          stop_name: "104 St",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J15",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "J",
          stop_id: "J16",
          stop_name: "85 St-Forest Pkwy",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J17",
          stop_name: "75 St-Elderts Ln",
        },
        {
          borough: "Brooklyn",
          service: "J",
          stop_id: "J19",
          stop_name: "Cypress Hills",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J20",
          stop_name: "Crescent St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J21",
          stop_name: "Norwood Av",
        },
        {
          borough: "Brooklyn",
          service: "J",
          stop_id: "J22",
          stop_name: "Cleveland St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J23",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J24",
          stop_name: "Alabama Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J27",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J28",
          stop_name: "Chauncey St",
        },
        {
          borough: "Brooklyn",
          service: "J",
          stop_id: "J29",
          stop_name: "Halsey St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J30",
          stop_name: "Gates Av",
        },
        {
          borough: "Brooklyn",
          service: "J",
          stop_id: "J31",
          stop_name: "Kosciuszko St",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M11",
          stop_name: "Myrtle Av",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M12",
          stop_name: "Flushing Av",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M13",
          stop_name: "Lorimer St",
        },
        {
          borough: "Brooklyn",
          service: "JM",
          stop_id: "M14",
          stop_name: "Hewes St",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M16",
          stop_name: "Marcy Av",
        },
        {
          borough: "Manhattan",
          service: "JMZ",
          stop_id: "M18",
          stop_name: "Delancey St-Essex St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M19",
          stop_name: "Bowery",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M20",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M21",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M22",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M23",
          stop_name: "Broad St",
        },
      ],
    },
    {
      line: "Z",
      stops: [
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G05",
          stop_name: "Jamaica Center-Parsons/Archer",
        },
        {
          borough: "Queens",
          service: "EJZ",
          stop_id: "G06",
          stop_name: "Sutphin Blvd-Archer Av-JFK Airport",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J12",
          stop_name: "121 St",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J14",
          stop_name: "104 St",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J15",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "JZ",
          stop_id: "J17",
          stop_name: "75 St-Elderts Ln",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J20",
          stop_name: "Crescent St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J21",
          stop_name: "Norwood Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J23",
          stop_name: "Van Siclen Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J24",
          stop_name: "Alabama Av",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J27",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J28",
          stop_name: "Chauncey St",
        },
        {
          borough: "Brooklyn",
          service: "JZ",
          stop_id: "J30",
          stop_name: "Gates Av",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M11",
          stop_name: "Myrtle Av",
        },
        {
          borough: "Brooklyn",
          service: "JMZ",
          stop_id: "M16",
          stop_name: "Marcy Av",
        },
        {
          borough: "Manhattan",
          service: "JMZ",
          stop_id: "M18",
          stop_name: "Delancey St-Essex St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M19",
          stop_name: "Bowery",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M20",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M21",
          stop_name: "Chambers St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M22",
          stop_name: "Fulton St",
        },
        {
          borough: "Manhattan",
          service: "JZ",
          stop_id: "M23",
          stop_name: "Broad St",
        },
      ],
    },
    {
      line: "N",
      stops: [
        {
          borough: "Brooklyn",
          service: "DFNQ",
          stop_id: "D43",
          stop_name: "Coney Island-Stillwell Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N02",
          stop_name: "8 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N03",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N04",
          stop_name: "New Utrecht Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N05",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N06",
          stop_name: "20 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N07",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N08",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N09",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N10",
          stop_name: "86 St",
        },
        {
          borough: "Brooklyn",
          service: "NQ",
          stop_id: "N12",
          stop_name: "S.B. Coney Island",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q03",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q04",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q05",
          stop_name: "96 St",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R01",
          stop_name: "Astoria-Ditmars Blvd",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R03",
          stop_name: "Astoria Blvd",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R04",
          stop_name: "30 Av",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R05",
          stop_name: "Broadway",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R06",
          stop_name: "36 Av",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R08",
          stop_name: "39 Av-Dutch Kills",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R09",
          stop_name: "Queensboro Plaza",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R11",
          stop_name: "Lexington Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R13",
          stop_name: "5 Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R14",
          stop_name: "57 St-7 Av",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R15",
          stop_name: "49 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R16",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R18",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R19",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R20",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R21",
          stop_name: "8 St-NYU",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R22",
          stop_name: "Prince St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R23",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R24",
          stop_name: "City Hall",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R25",
          stop_name: "Cortlandt St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R26",
          stop_name: "Rector St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R27",
          stop_name: "Whitehall St-South Ferry",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R28",
          stop_name: "Court St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R29",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R31",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R32",
          stop_name: "Union St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R33",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R34",
          stop_name: "Prospect Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R35",
          stop_name: "25 St",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R39",
          stop_name: "45 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R40",
          stop_name: "53 St",
        },
        {
          borough: "Brooklyn",
          service: "NQRW",
          stop_id: "R41",
          stop_name: "59 St",
        },
      ],
    },
    {
      line: "Q",
      stops: [
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D24",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D25",
          stop_name: "7 Av (B)",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D26",
          stop_name: "Prospect Park",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D27",
          stop_name: "Parkside Av",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D28",
          stop_name: "Church Av",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D29",
          stop_name: "Beverley Rd",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D30",
          stop_name: "Cortelyou Rd",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D31",
          stop_name: "Newkirk Plaza",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D32",
          stop_name: "Avenue H",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D33",
          stop_name: "Avenue J",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D34",
          stop_name: "Avenue M",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D35",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D37",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D38",
          stop_name: "Neck Rd",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D39",
          stop_name: "Sheepshead Bay",
        },
        {
          borough: "Brooklyn",
          service: "BQ",
          stop_id: "D40",
          stop_name: "Brighton Beach",
        },
        {
          borough: "Brooklyn",
          service: "Q",
          stop_id: "D41",
          stop_name: "Ocean Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "FQ",
          stop_id: "D42",
          stop_name: "W 8 St-NY Aquarium",
        },
        {
          borough: "Brooklyn",
          service: "DFNQ",
          stop_id: "D43",
          stop_name: "Coney Island-Stillwell Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N02",
          stop_name: "8 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N03",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N04",
          stop_name: "New Utrecht Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N05",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N06",
          stop_name: "20 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N07",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N08",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N09",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N10",
          stop_name: "86 St",
        },
        {
          borough: "Brooklyn",
          service: "NQ",
          stop_id: "N12",
          stop_name: "S.B. Coney Island",
        },
        {
          borough: "Manhattan",
          service: "Q",
          stop_id: "Q01",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q03",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q04",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q05",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R14",
          stop_name: "57 St-7 Av",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R15",
          stop_name: "49 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R16",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R18",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R19",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R20",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R21",
          stop_name: "8 St-NYU",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R22",
          stop_name: "Prince St",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
        {
          borough: "Brooklyn",
          service: "NQRW",
          stop_id: "R41",
          stop_name: "59 St",
        },
      ],
    },
    {
      line: "R",
      stops: [
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G08",
          stop_name: "Forest Hills-71 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G09",
          stop_name: "67 Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G10",
          stop_name: "63 Dr-Rego Park",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G11",
          stop_name: "Woodhaven Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G12",
          stop_name: "Grand Av-Newtown",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G13",
          stop_name: "Elmhurst Av",
        },
        {
          borough: "Queens",
          service: "EFMR",
          stop_id: "G14",
          stop_name: "Jackson Hts-Roosevelt Av",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G15",
          stop_name: "65 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G16",
          stop_name: "Northern Blvd",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G18",
          stop_name: "46 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G19",
          stop_name: "Steinway St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G20",
          stop_name: "36 St",
        },
        {
          borough: "Queens",
          service: "EMR",
          stop_id: "G21",
          stop_name: "Queens Plaza",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q03",
          stop_name: "72 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q04",
          stop_name: "86 St",
        },
        {
          borough: "Manhattan",
          service: "NQR",
          stop_id: "Q05",
          stop_name: "96 St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R11",
          stop_name: "Lexington Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R13",
          stop_name: "5 Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R14",
          stop_name: "57 St-7 Av",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R15",
          stop_name: "49 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R16",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R18",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R19",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R20",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R21",
          stop_name: "8 St-NYU",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R22",
          stop_name: "Prince St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R23",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R24",
          stop_name: "City Hall",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R25",
          stop_name: "Cortlandt St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R26",
          stop_name: "Rector St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R27",
          stop_name: "Whitehall St-South Ferry",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R28",
          stop_name: "Court St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R29",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R31",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R32",
          stop_name: "Union St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R33",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R34",
          stop_name: "Prospect Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R35",
          stop_name: "25 St",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R39",
          stop_name: "45 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R40",
          stop_name: "53 St",
        },
        {
          borough: "Brooklyn",
          service: "NQRW",
          stop_id: "R41",
          stop_name: "59 St",
        },
        {
          borough: "Brooklyn",
          service: "R",
          stop_id: "R42",
          stop_name: "Bay Ridge Av",
        },
        {
          borough: "Brooklyn",
          service: "R",
          stop_id: "R43",
          stop_name: "77 St",
        },
        {
          borough: "Brooklyn",
          service: "R",
          stop_id: "R44",
          stop_name: "86 St",
        },
        {
          borough: "Brooklyn",
          service: "R",
          stop_id: "R45",
          stop_name: "Bay Ridge-95 St",
        },
      ],
    },
    {
      line: "W",
      stops: [
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N02",
          stop_name: "8 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N03",
          stop_name: "Fort Hamilton Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N04",
          stop_name: "New Utrecht Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N05",
          stop_name: "18 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N06",
          stop_name: "20 Av",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N07",
          stop_name: "Bay Pkwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N08",
          stop_name: "Kings Hwy",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N09",
          stop_name: "Avenue U",
        },
        {
          borough: "Brooklyn",
          service: "NQW",
          stop_id: "N10",
          stop_name: "86 St",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R01",
          stop_name: "Astoria-Ditmars Blvd",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R03",
          stop_name: "Astoria Blvd",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R04",
          stop_name: "30 Av",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R05",
          stop_name: "Broadway",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R06",
          stop_name: "36 Av",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R08",
          stop_name: "39 Av-Dutch Kills",
        },
        {
          borough: "Queens",
          service: "NW",
          stop_id: "R09",
          stop_name: "Queensboro Plaza",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R11",
          stop_name: "Lexington Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R13",
          stop_name: "5 Av/59 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R14",
          stop_name: "57 St-7 Av",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R15",
          stop_name: "49 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R16",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R17",
          stop_name: "34 St-Herald Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R18",
          stop_name: "28 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R19",
          stop_name: "23 St",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R20",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R21",
          stop_name: "8 St-NYU",
        },
        {
          borough: "Manhattan",
          service: "NQRW",
          stop_id: "R22",
          stop_name: "Prince St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R23",
          stop_name: "Canal St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R24",
          stop_name: "City Hall",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R25",
          stop_name: "Cortlandt St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R26",
          stop_name: "Rector St",
        },
        {
          borough: "Manhattan",
          service: "NRW",
          stop_id: "R27",
          stop_name: "Whitehall St-South Ferry",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R28",
          stop_name: "Court St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R29",
          stop_name: "Jay St-MetroTech",
        },
        {
          borough: "Brooklyn",
          service: "BDNQRW",
          stop_id: "R30",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R31",
          stop_name: "Atlantic Av-Barclays Ctr",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R32",
          stop_name: "Union St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R33",
          stop_name: "4 Av-9 St",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R34",
          stop_name: "Prospect Av",
        },
        {
          borough: "Brooklyn",
          service: "DNRW",
          stop_id: "R35",
          stop_name: "25 St",
        },
        {
          borough: "Brooklyn",
          service: "DNQRW",
          stop_id: "R36",
          stop_name: "36 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R39",
          stop_name: "45 St",
        },
        {
          borough: "Brooklyn",
          service: "NRW",
          stop_id: "R40",
          stop_name: "53 St",
        },
        {
          borough: "Brooklyn",
          service: "NQRW",
          stop_id: "R41",
          stop_name: "59 St",
        },
      ],
    },
    {
      line: "L",
      stops: [
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L01",
          stop_name: "8 Av",
        },
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L02",
          stop_name: "6 Av",
        },
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L03",
          stop_name: "14 St-Union Sq",
        },
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L05",
          stop_name: "3 Av",
        },
        {
          borough: "Manhattan",
          service: "L",
          stop_id: "L06",
          stop_name: "1 Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L08",
          stop_name: "Bedford Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L10",
          stop_name: "Lorimer St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L11",
          stop_name: "Graham Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L12",
          stop_name: "Grand St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L13",
          stop_name: "Montrose Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L14",
          stop_name: "Morgan Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L15",
          stop_name: "Jefferson St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L16",
          stop_name: "DeKalb Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L17",
          stop_name: "Myrtle-Wyckoff Avs",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L19",
          stop_name: "Halsey St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L20",
          stop_name: "Wilson Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L21",
          stop_name: "Bushwick Av-Aberdeen St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L22",
          stop_name: "Broadway Junction",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L24",
          stop_name: "Atlantic Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L25",
          stop_name: "Sutter Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L26",
          stop_name: "Livonia Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L27",
          stop_name: "New Lots Av",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L28",
          stop_name: "East 105 St",
        },
        {
          borough: "Brooklyn",
          service: "L",
          stop_id: "L29",
          stop_name: "Canarsie-Rockaway Pkwy",
        },
      ],
    },
    {
      line: "S",
      stops: [
        {
          borough: "Manhattan",
          service: "S",
          stop_id: "901",
          stop_name: "Grand Central-42 St",
        },
        {
          borough: "Manhattan",
          service: "S",
          stop_id: "902",
          stop_name: "Times Sq-42 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H04",
          stop_name: "Broad Channel",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H12",
          stop_name: "Beach 90 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H13",
          stop_name: "Beach 98 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H14",
          stop_name: "Beach 105 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H15",
          stop_name: "Rockaway Park-Beach 116 St",
        },
        {
          borough: "Queens",
          service: "AS",
          stop_id: "H19",
          stop_name: "Broad Channel",
        },
        {
          borough: "Brooklyn",
          service: "S",
          stop_id: "S01",
          stop_name: "Franklin Av",
        },
        {
          borough: "Brooklyn",
          service: "S",
          stop_id: "S03",
          stop_name: "Park Pl",
        },
        {
          borough: "Brooklyn",
          service: "S",
          stop_id: "S04",
          stop_name: "Botanic Garden",
        },
      ],
    },
  ];
  for (let line of lines) {
    for (let stop of line["stops"]) {
      if (stop["stop_id"] == stopId) {
        console.log(stop["service"]);
        return stop["service"];
      }
    }
  }
};

export default { getStops, getStopService };
